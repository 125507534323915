// Components
import Vue from "vue";


import Home from '@/views/Home.vue';
Vue.component("Home", Home);

import Navbar from "@/views/BaseComponents/Navbar.vue";
Vue.component("Navbar", Navbar);

import Sidebar from "@/views/BaseComponents/Sidebar.vue";
Vue.component("Sidebar", Sidebar);

import PlusButton from "@/views/BaseComponents/PlusButton.vue";
Vue.component("PlusButton", PlusButton);

import Header from "@/views/BaseComponents/Header.vue";
Vue.component("Header", Header);

import Bubble from "@/views/BaseComponents/Bubble.vue";
Vue.component("Bubble", Bubble);

import Request from "@/views/BaseComponents/Request.vue";
Vue.component("Request", Request);

// Tip
Vue.config.productionTip = false;