<template>
<div>

    <b-navbar type="dark" :variant="theme" id="topbar" class="row p-2 m-0 border-bottom">
        
        <!-- menu items -->
        <b-button v-for="item in items" 
            :key="item.name" 
            v-b-toggle="item.sidebar" 
            @click="isThis(item.link) || empty(item.link) ? '' : $router.push({name: item.link})"
            :variant="theme" 
            :class="[ isThis(item.link) ? 'active' : '', 'col item']" 
            >
            
            <!-- icon -->
            <b-icon :icon="item.icon" />
            <!-- notify -->
            <span v-if="item.n && notify[item.link]" class="req">{{ notify[item.link] }}</span>
            <!-- name -->
            <span class="name d-none d-sm-none d-md-none d-lg-inline-block ml-3" v-t="item.name"></span>
            

        </b-button>
    </b-navbar>

</div>
</template>

<script>
export default {
    computed: {
        route() { return this.$route.name; },
        items() { return [
           { icon: "three-dots-vertical", name: "nav.menu", link: "", sidebar: "sidebar", n: false},
           { icon: "clipboard", name: "nav.project", link: "Project", n: false },
           { icon: "archive", name: "nav.board", link: "Board", n: this.auth.project && this.auth.project.role!='request' ? true : false },
           { icon: "people", name: "nav.members", link: "Members", n: this.auth.project && this.auth.project.role=='admin' ? true : false },
           { icon: "folder", name: "nav.files", link: "Files", n: false },
           { icon: "clock", name: "nav.tasks", link: "Tasks", n: true },
           { icon: "person-circle", name: "nav.profile", link: "Profile", n: false },
       ]},
    },
    data() { return {
       theme: "primary",
    }},
    methods: {
        isThis(path) { if(path == this.route) return true; else return false; },
        empty(path) { if(path=="") return true; }
    }
}
</script>

<style scoped>
    #topbar { 
        position: fixed; 
        top: 0; 
        left: 0; 
        width: 100%; 
        z-index: 900;
        background: rgb(41,126,208);
        background: linear-gradient(90deg, rgba(41,126,208,1) 0%, rgba(63,131,255,1) 40%, rgba(0,212,255,1) 100%);
     }
     .item {
         background-color:transparent;
         border: none;
     }
</style>