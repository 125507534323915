<template>
  <div>
    <div id="intro" class="text-muted">
      <h1 class="text-primary mt-5"><b-icon-people-fill /> <b>YEDU</b>app!</h1>
      <p v-html="$t('site.intro')"></p>

      <!-- install banner -->
      <InstallBaner v-if="!isFullscreen" />

    </div>
  </div>
</template>

<style scoped>
  #intro { 
    margin-top: 30vh;    
  }
</style>

<script>
import InstallBaner from "@/views/BaseComponents/InstallBaner.vue";
export default {
  components: { InstallBaner },
}
</script>
