// mixin
import Vue        from "vue";
import axios      from "axios";
import VueAxios   from "vue-axios";
import Confirm from '@/views/BaseComponents/Confirm.vue';
import Lightbox from "@/views/BaseComponents/Lightbox.vue";
import i18n       from  "@/include/i18n.js";

axios.defaults.withCredentials = true;

Vue.use(VueAxios, axios);

Vue.mixin({

  data(){return{
    imgExt: ['jpg','jpeg','png','gif'],
    filesURL: 'api/files/',
  }},

  /**
   * COMPUTED PROPERTIES
   */
  computed: {
    auth() { if(this.$store.state.user) return this.$store.state.user; else return false; },
    isFullscreen() { return window.innerHeight == screen.height ? true : false; },
    notify: { 
      get()   { return this.$store.state.notify; },
      set(val){ Vue.set(this.$store.state.notify,val[0],val[1]); }
    },
  },
  
  /**
   * METHODS
   */
    methods: {

    // LOCALE
    setLocale(lang) { 
      i18n.locale = lang; 
      this.moment.locale(lang); },

    // GET LINK
    getEmbed(text) {
      let regex = /\bhttps?:\/\/(?:www\.)?(youtube|youtu|vimeo)\.(?:com|be)\/(?:watch\?v=)?([^\s]*)/ig;
      let link = regex.exec(text);
      if(!link) return false;
      if(link[1]=='youtu') link[1]='youtube';
      switch(link[1]) {
        case 'youtu':
        case 'youtube':
          link = 'https://www.youtube.com/embed/'+link[2];
          break;
        case 'vimeo':
          link = 'https://player.vimeo.com/video/'+link[2];
          break;
      }
      return link;
    },
   

    // FIND INDEX BY ID
    index(arr,id) { return this[arr].findIndex(x => x.id === parseInt(id)); },
    
    // NOTIFY NAVBAR
    //notify(name,val) { this.$store.commit('notify',[name,val]); },

    // SCROLL TOP
    goTop() { window.scroll({ top: 0, left: 0, behavior: 'smooth' }) },
    
    // SCROLL BOTTOM FUNCTION
    scroll () { window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) { this.scrollBottom(); } } },

    // COMMIT
    commit(state,val) { this.$store.commit(state, val); },
    
    // DISPATCH
    dispatch(state,val) { this.$store.dispatch(state,val); },
    
    // CALL FUNCTION
    call(name=null,params=null) { if(name) this[name](params); },
    
    // LOGOUT
    logout() { this.authUser(false); },
    
    // GET LAST INDEX
    lastIndexOf(arr) { arr = Object.keys(arr); return arr[arr.length-1]; },

    // GET AVATAR
    getAvatar(id) { return "api/files?avatar=" + id; },


    /**
     * CONFIRM
     */
    confirm(text=null,action=null,params=null) {
      if(!text) text = this.$t('confirm.text');
      text = this.$createElement(Confirm,{props: { text: text }});
      //let h = this.$createElement;
      //text = h('div', { class: ['confirm','text-center']}, [ text ]);
      this.$bvModal.msgBoxConfirm(text, {
        title: this.$t('confirm.title'),
        headerBgVariant: 'primary',
        headerTextVariant: 'white',
        okVariant: 'danger',
        okTitle: this.$t('confirm.yes'),
        cancelTitle: this.$t('confirm.no'),
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if(action) { if(value) this.call(action,params); }
        })
    },

    /**
     * SHOW IMAGE
     */
    showImage(img) {
      let content = this.$createElement(Lightbox,{props: { img: img }});
      this.$bvModal.msgBoxOk(content, { 
        centered: true, 
        footerClass: 'd-none',
        hideHeaderClose: false,
        hideHeader: false,
        title: img.title
       });
      },

    /**
     * PARSE ERROR ARRAY
     */
    parseErrors(errors) {
      if(errors.constructor === Object) {
      let all = '';
      errors = Object.values(errors).flat();
      for(let error of errors) {
        error = error.split("|");
        all = all + this.$t('error.'+error[0],{field: error[1]}) + "<br/>"; }
      return all; }
      else return this.$t(errors);
    },
        
    /** 
     * DOWNLOAD
     */
    download(file,title=file) {
      axios({
        url: this.filesURL+file,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', title);
         document.body.appendChild(fileLink);
         fileLink.click();
      });
    },

    /**
     * AUTH USER
     */
    async authUser(user=null) {
      if(user===false) user = await this.axon("get","logout");
      else if(user==null) user = await this.axon("get","login");
      this.commit('user',user);
      // if authorized
      if(user) {
        if(user.lang) this.setLocale(user.lang);
        if(this.$route.name=="Login") this.$router.push({name: "Home"}); 
        return this.$store.state.user;
      }
      // if not…
      if(this.$route.name!="Login") this.$router.push({name: "Login"}); 
    },

    /**
     * GET AXIOS REQUEST
     */
    async axon(type="get",query,val=null,store=false) {
      // run spinner
      this.commit('setLoading',true);
      let config = { 
        method: type, 
        url: "/api/" + query, 
        data: null
      }
      // add data if not get
      if(type!="get") { 
        config.data = val;
        if(store==true) config.headers = { "Content-Type": "multipart/form-data" }
      }

      // start request
      var res = null;
      await axios(config)
        .then(response=>{
          this.commit('setLoading',false);
          /** 
           * GET 
           */
          if(type=='get') {
            // if store then dispatch val with data
            if(store === true ) { this.dispatch(val, response.data); }
            // if store is string dispatch val with array of store and data
            else if(store.constructor === String) { this.dispatch(val, [ store, response.data ]); }
            // if not store send data to this.data
            else if(val) this[val] = response.data;
          }
          /** 
           * OTHERS 
           */
          else {
            let info = 'OK.';
            if(typeof response.data == 'string') info = response.data;
            else if(response.data.info) info = response.data.info;
            if(info!='') this.$store.commit("alert",this.$t(info));
          }
          // return response data
          res = response.data;
        })
        /**
         * ERRORS
         */
        .catch(error => { 
          this.commit('setLoading',false);
          if(val==="auth") this.auth = "false";
          let errors = error.response ? error.response.data.errors : null;
          if(errors) this.commit("alert",[this.parseErrors(errors),"danger"]);
          
        });
        return res;
    },

    /**
     * DELETE ITEM
     */
    delItem(i,id=null,index=null,text=null) {
      // if not confirmed
      if(!i.confirmed) {
          if(index==null) index = id;
          if(!text) text='confirm.delete';
          this.confirm(this.$t(text),'delItem',
              {   confirmed: true, 
                  items: i,
                  id: id, 
                  index: index });
      // if confirmed
      } else {
          this.axon('delete',i.items+'/'+i.id); 
          this[i.items].splice(i.index,1);
      }
    },
    /**
     * CREATE ITEM
     */
    async createItem(item,data,files=false) {
      let items = item + "s";
      let res = await this.axon("post",items,data,files);
      if(res && res.id) { 
          this.$bvModal.hide('new-'+item);
          let add = await this.axon('get',items+'/'+res.id);
          this[items].unshift(add);
          return res.id; }
      },

    /**
     * UPDATE ITEM
     */
    async changeItem(arr,data,ix=null) {
      let url = arr + "/" + data.id;
      await this.axon("put",url,data);
      if(ix!=null) {
        let item = await this.axon("get",url);
        this.$set(this[arr],ix,item); }
    },

    /** 
     * DETACH USER
     */
    async xtachUser(data) {
      if(!data.action) return false;
      if(!data.confirmed) {
        if(!data.uid) data.uid='';
        this.confirm(this.$t('project.confirm-'+data.action),'xtachUser',
            {   confirmed: true, 
                pid: data.pid,
                uid: data.uid, 
                index: data.index ? data.index : null,
                func: data.func ? data.func : null,
                action: data.action
            });
    // if confirmed
      } else {
        let url = 'projects/'+data.pid;
        let send = {};
        send[data.action] = data.uid;
        await this.axon("put",url,send);
        if(data.index!=null && data.action=='detach') this.users.splice(data.index,1);
        if(data.func!=null) this.call(data.func);
      }
    },

}

});
