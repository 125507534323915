<template>
<div>
    <img :src="filesURL+img.name" :alt="img.title" :title="img.title" class="img-fluid" @click="download(img.name)" />
</div>
</template>

<script>
export default {
    name: "Lightbox",
    props: ['img']
}
</script>