<template>
<div class="ontop">

    <!-- button + -->
    <b-button 
        :variant="theme" 
        class="add" 
        @click="$emit('click')"
        v-b-toggle.button-menu >
    <b-icon :icon="icon" class="plus" />
    </b-button>

    <!-- button menu list -->
    <b-collapse v-if="items" id="button-menu" class="pointer">
        <b-list-group>
            <b-list-group-item 
                v-for="item in items" 
                v-b-toggle.button-menu
                :key="item.name"
                @click="item.modal ? $bvModal.show(item.modal) : item.route ? $router.push({name: item.route}) : ''"
            >
                <a v-if="item.link" :href="item.link"><span>{{ $t('item.name') }}</span></a>
                <span v-else>{{ $t(item.name) }}</span>
            </b-list-group-item>
        </b-list-group>
    </b-collapse>
    
</div>
</template>

<style scoped>
    .add {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border-radius: 40px;
        padding: 13px 15px;    
        font-size: 1.2em;
        box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
    }
    .ontop {
        z-index: 800;
    }
    .rotate {
        transform: rotate(90deg);
    }
       #button-menu {
        position: fixed;
        bottom: 90px;
        right: 20px;
        box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.2);
    }
    .list-group-item {
        padding: 5px 15px;
        text-align: left;
        font-size: 0.9em;
    }
    .list-group-item span::before {
        content: '◎ ';
    }
    .add {  transition: transform .2s ease-in-out;   }
    .add[aria-expanded="true"] {
        transform: rotate(135deg); }
</style>

<script>
export default {
    props: {
        theme: {
            type: String,
            default: "primary"
        },
        action: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: null
            },
        icon: {
            type: String,
            default: "plus"
        }

    },
}
</script>
