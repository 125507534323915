// pusher
require("@/include/pusher.js");
require("@/include/markdown.js");
// vendor
import Vue        from  "vue";
import moment     from  "moment";
import App        from  "@/App.vue";
import bootstrap  from  "@/include/bootstrap.js";
import components from  "@/include/components.js";
import store      from  "@/include/store.js";
import mixin      from  "@/include/mixin.js";
import i18n       from  "@/include/i18n.js";
import router     from  "@/include/router.js";
import "moment/locale/pl";

Vue.prototype.moment = moment;

// Vue
new Vue({
  router,
  mixin,
  store,
  bootstrap,
  components,
  i18n,
  render: h => h(App),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
}
}).$mount("#app");
