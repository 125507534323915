<template>
<div>

  <b-sidebar id="sidebar" bg-variant="light" text-variant="dark" class="text-center" shadow>
    
    <!-- logo -->
    <h3 class="text-primary"><b-icon-people-fill /> <b>YEDU</b>app!</h3>
    
    <!-- project info -->
    <div v-if="auth.project">
      <small class="text-muted">{{ $t('project.current') }}</small>
      <h5 class="text-success" v-text="auth.project.title"></h5>
    </div>

    <!-- menu items -->
    <b-list-group>
      <b-list-group-item 
          v-for="item in items" 
          :key="item.name" 
          @click="item.funct ? call(item.funct) : item.route ? $router.push({name: item.route}) : ''" 
          class="pointer" >
            <b-icon v-if="item.icon" :icon="item.icon" class="icon text-muted" /> 
            <a v-if="item.link" :href="item.link" :target="item.target" class="text-dark">{{ $t(item.name) }}</a>
            <span v-else>{{ $t(item.name) }}</span>
      </b-list-group-item>
    </b-list-group>

    <!-- footer -->
    <Footer />

  </b-sidebar>
  
</div>
</template>

<style>
  #sidebar { z-index: 1000; }
  #sidebar img {
    max-width: 80% !important;
  }
  .icon { 
    position: absolute;
    left: 10px;  
  }
</style>

<script>
import Footer from "@/views/BaseComponents/Footer.vue";
export default {
  components: { Footer },
  data() { return{
    items: [
      { name: "board.global",   route: "GlobalBoard",       icon: "archive"},
      { name: "project.change", route: "Project",           icon: "clipboard"},
      // { name: "Settings", link: "", icon: "gear" },
      { name: "YEDU Wiki",      link: "http://wiki.yedu.eu",icon: "link45deg", target: "_blank"},
      { name: "profile.logout", funct: "logout",         icon: "box-arrow-left"}
    ]
  }},

}
</script>