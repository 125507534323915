<template>
  <div id="app">
    <div class="container px-2 mb-5 pb-5">
    
    <!-- menus -->
    <div v-if="auth && loaded">
      <Navbar />
      <Sidebar />
    </div>
    
    <!-- content -->
    <transition name="fade" mode="out-in">
      <router-view :key="$route.path" v-if="condition" />
      <Home v-else />
    </transition>

    <Bubble :alert="alert" />
    <Bubble :alert="loading" loading />
    
    </div>
  </div>
</template>

<script>
export default {
  name: "Yapp",
  data() {return{
    loaded: false,
  }},
  computed: { 
    alert() { return this.$store.state.alert; },
    loading() { return this.$store.state.loading; },
    condition() {
      let pRoute = [ 'Board','Members','Files','Tasks'];
      if(!this.$route 
        || (this.$route && pRoute.includes(this.$route.name) && !this.auth.project)) return false;
      return true;
    }},
  async created() { 
    await this.authUser(); 
    if(this.auth.project) {
      this.notify = ['Board',this.auth.project.info.newposts];
      this.notify = ['Members',this.auth.project.info.requests];
    }
    this.loaded = true;
    
    window.Echo.private('notify').listen('NotifyEvent', (incoming) => { 
      if(this.auth && this.auth.project && this.auth.project.id 
        && incoming.uid!=this.auth.id && incoming.pid==this.auth.project.id) {
          if(!this.notify[incoming.name]) this.notify = [incoming.name,0];
          this.notify = [incoming.name,this.notify[incoming.name]+1];
        }
    });


  },

  watch: {
    $route() {
      if(this.auth) window.Echo.leave('board.0');
      if(this.auth && this.auth.project.id) window.Echo.leave('board.'+this.auth.project.id);
    }
  }
}
</script>


<style lang="scss">
  
  /* global styles */

  .nl2br  { white-space: pre-wrap; }

  .text-green { color: #8AE234; }

  .text-small { font-size: 0.9em; }

  .text-tiny  { font-size: 0.7em; }

  .text-big   { font-size: 1.2em !important; }

  .text-large { font-size: 4em; }

  .max-100    { max-width: 100%; }

  .pointer    { cursor: pointer; }

  .modal      { z-index: 9999; }

  .limit-height { max-height: 250px;
    overflow: auto; }

  .markdown-body strong { font-weight: bolder !important; }
  .markdown-body blockquote { margin-bottom: 0 !important; }
  .markdown-body hr { height: 1px !important; margin: 10px 0 !important; }

  .break-lines { 
    overflow-wrap: break-word; 
    word-break: break-all;
    height: auto;
  }

  .req {
    position: absolute;
    top: 6px;
    font-size: 0.5em;
    color: white;
    font-weight: bold;
    background: #DC3545;
    padding: 0.3em 0.8em;
    margin: 0 0 0 2px;
    border-radius: 10px;
    display: inline-block;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding: 53px 0 0 0;
    overflow-wrap: break-word; 

  }

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
