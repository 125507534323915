<template>
      <div id="install-baner" class="bg-white position-fixed border-top p-3">
        <h5 class="text-primary">{{ $t('site.install') }}</h5>
        <p>
            {{ $t('site.click') }}
            <span class="d-inline-block bg-white p-1 border mx-1"><b-icon icon="three-dots-vertical" /></span>
            <span v-html="$t('site.addtohome')" />
        </p>
      </div>
</template>

<style scoped>
#install-baner {
    bottom: 0;
    left: 0;
    width: 100vw;
}
</style>

<script>
export default {
    
}
</script>