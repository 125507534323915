<template>
    <div>
        <b-alert 
            :class="['bubble',loading ? 'loading' : 'normal', 'position-fixed d-inline-block text-small']"
            :dismissible="alert.dismiss"
            :show="alert.show"
            :variant="alert.variant"
            @dismissed="clear"
            fade
            >
            <b-icon :icon="alert.variant == 'danger' ? 'exclamation-triangle' : alert.icon" class="mr-2" :animation="alert.animation ? alert.animation : ''" /> 
            <span v-html="alert.text" />
        </b-alert>
    </div>
</template>

<style scoped>
.bubble { z-index: 9010; width: auto; }
.loading { top: 60px; right: 10px; }
.normal { bottom: 0; left: 10px; }
</style>

<script>
export default {
    props: {
        alert: Object,
        loading: Boolean 
    },
    methods: {
        clear() {
            this.$store.commit('noAlert');
        },
    }
}
</script>