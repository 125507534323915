import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    /**
     * Notifications
     */
    notify: { },

    /**
     * Loading state
     */
    loading: {
        show: false, 
        variant: "warning", 
        text: "Loading…", 
        animation: "spin",
        icon: "arrow-clockwise",
        dismiss: false },

    /**
     * Alert box
     */
    alert: { 
      show: false, 
      variant: "success", 
      text: "", 
      icon: "info-circle",
      dismiss: true, 
      animation: false },

    /**
     * Auth state
     */
    user: null
  },
  mutations: {

    notify(s,v) { s.notify[v[0]]=v[1]; },
    
    /**
     * Update auth
     */
    user(s,v) { s.user = v; },

    /**
     * Execute alert
     */
    alert(s, v) { 
      s.alert.show = false;
      if(typeof v === 'string') {
        s.alert.text = v; 
        s.alert.variant = "success";
      } 
      else {
        s.alert.text = v[0],
        s.alert.variant = v[1] ? v[1] : "success"; }
      s.alert.show = 5;
    },

    /**
     * Reset alert
     */
    noAlert(s) { s.alert.show = false; },
    
    /**
     * Set loading info
     */
    setLoading(s,v) {
      s.loading.show = false;
      if(v) s.loading.show = true; }
  },
  modules: {
  }
})
