<template>
<div>
        <div v-if="!global && auth.project && auth.project.role=='request'" class="text-primary text-center my-3">
            <b-icon icon="alarm" class="text-large d-block mx-auto my-3" />
            <h3 class="text-primary" v-text="$t('role.request')" />
        </div>
        <div v-else-if="contents && !contents.length>0 && (auth.project ? auth.project.role!='request' : true)">
            <b-icon icon="exclamation-triangle" class="text-large text-muted d-block mx-auto mb-3" />
            <p class="text-muted" v-if="text" v-text="text" />
        </div>
</div>
</template>

<script>
export default {
    props: ['contents','text','global']
}
</script>