import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import axios from "axios";

function routeAllowed(name) {
  var freeRoutes = [ 'Login', 'Signup' ];
  if(freeRoutes.includes(name)) return true; 
  else return false; }


Vue.use(VueRouter)

  const routes = [

  { path: '/', name: 'Home', component: Home, props: true },

  { path: '/login', name: 'Login', props: true,
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue') },  

  { path: '/signup', name: 'Signup', props: true,
    component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue') },  

  { path: '/board', name: 'Board', props: true,
    component: () => import(/* webpackChunkName: "board" */ '@/views/Board.vue') },

  { path: '/global', name: 'GlobalBoard', props: { default:true, global: true },
    component: () => import(/* webpackChunkName: "board" */ '@/views/Board.vue') },

  { path: '/image', name: 'Image', props: true,
    component: () => import(/* webpackChunkName: "image" */ '@/views/Image.vue') },

  { path: '/profile', name: 'Profile', props: true,
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue') },

  { path: '/tasks', name: 'Tasks', props: true,
    component: () => import(/* webpackChunkName: "tasks" */ '@/views/Tasks.vue') },

  { path: '/files', name: 'Files', props: true,
    component: () => import(/* webpackChunkName: "files" */ '@/views/Files.vue') },

  { path: '/project', name: 'Project', props: true,
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue') },

  { path: '/members', name: 'Members', props: true,
    component: () => import(/* webpackChunkName: "members" */ '@/views/Members.vue') },
 
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// redirect to login if not authorized
router.beforeEach((to, from, next) => {
  //router.app.$store.state.notify[to.name] = null;
  // user verified
  if(to.path=='/login/verified') router.app.$store.commit('alert',router.app.$t('success.verified'));
  if(to.path=='/login/reset') router.app.$store.commit('alert',router.app.$t('success.reset'));
  else next();
});

/// redirect to login if not authorized 401
axios.interceptors.response.use(undefined, function (err) {
  let error = err.response.status;
  let route = router.currentRoute.name;
  if (error === 401) {
    router.app.$store.state.user = null;
    if(!routeAllowed(route)) {
      router.push({name:"Login"});
      //router.app.$store.commit('alert',[router.app.$t('error.logout'),'danger']);
    }
  }
  return Promise.reject(err);
});

export default router
