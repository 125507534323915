<template>
    <div class="col-lg-12 text-uppercase text-muted my-2 header mb-2">
        {{ title }}
        <span v-if="!noProject && auth.project"> @ {{ auth.project.title }}</span>
    </div>
</template>

<style scoped>
    .header { letter-spacing: 2px; }
</style>

<script>
export default {
    props: {
        title: { type: String },
        noProject: { type: Boolean },
    },
}
</script>